import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const FilterTypes = [
  { label: "Equals", value: "=" },
  { label: "Do not equal", value: "!=" },
  { label: "Contains", value: "CONTAINS" },
  { label: "Does not contain", value: "DOESNOTCONTAIN" },
  { label: "Starts with", value: "STARTSWITH" },
  { label: "Ends with", value: "ENDSWITH" },
  { label: "Is empty", value: "ISEMPTY" },
  { label: "Is not empty", value: "ISNOTEMPTY" },
  { label: "Is anything", value: "ISANYTHING" },
];

const PulseListFilter = ({ columns, onApplyFilters }) => {
  const [filters, setFilters] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [nextFilterLogic, setNextFilterLogic] = useState("AND");
  const location = useLocation();

  const renderFilterChips = () => {
    return filters.map((filter, index) => (
      <div className="flex items-center bg-gray-200 rounded px-2 py-1 text-sm font-medium text-gray-700 mr-2">
        {index > 0 && (
        // Operator chip that indicates the logic used
        <div className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${filter.operator === 'OR' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'}`}>
          {filter.operator}
        </div>
      )}
        {filter.column} {filter.filterType} {filter.value}
        <button
          onClick={() => handleRemoveFilter(index)}
          className="ml-2 rounded p-1 text-gray-400 hover:text-gray-600"
        >
          <span style={{ fontSize: "1.5em" }}>&times;</span>
        </button>
      </div>
    ));
  };

  const handleRemoveFilter = (index) => {
    let newFilters = [...filters];
    if (newFilters[index].operator === 'AND' && newFilters[index + 1] && newFilters[index + 1].operator === 'OR') {
      let nextAndIndex = newFilters.findIndex((f, i) => i > index && f.operator === 'AND');
      nextAndIndex = nextAndIndex === -1 ? newFilters.length : nextAndIndex; // If there's no next 'AND', set to length of array.
      newFilters.splice(index, nextAndIndex - index);
    } else {
      // It's an 'AND' without a following 'OR', or it's an 'OR' without a subsequent 'OR', just remove this filter.
      newFilters.splice(index, 1);
    }
    console.log('newFilters: ', newFilters);
    setFilters(newFilters);
};

  const handleAddFilter = () => {
    setFilters([...filters, { column: "", filterType: "", value: "", operator: nextFilterLogic}]);
  };

  const handleChange = (index, field, value) => {
    const newFilters = [...filters];
    newFilters[index][field] = value;
    setFilters(newFilters);
  };

  const handleApplyFilters = () => {
    console.log('filters: ', filters);
    const formattedFilters = filters.map((f, index) => {
      // Prefix with '^' for all subsequent filters
      const prefix = index === 0 ? '' : `^${f.operator === 'OR' ? 'OR' : ''}`;
      return `${prefix}${f.column}${f.filterType}${f.value}`;
    }).join('');
    console.log('formatedFilter: ', formattedFilters);
    onApplyFilters(formattedFilters);
  };

  const handleClearFilter = () => {
    setFilters([]);
    onApplyFilters(filters);
  };

  useEffect(() => {
    // Parse the filter query param from the URL
    setFilters([]);
    const queryString = new URLSearchParams(location.search);
    const filterString = queryString.get('filter');
  
    if (filterString) {
      // Split the string on '^' to get individual filter expressions
      const filterExpressions = filterString.split('^');
  
      // Convert filter expressions into filter objects
      const initialFilters = filterExpressions.map((expr, index) => {
        // Determine the operator based on the presence of 'OR' and it not being the first filter
        const isOrOperator = expr.startsWith('OR') && index !== 0;
        // Remove 'OR' from the expression if it's there
        const cleanExpr = isOrOperator ? expr.replace('OR', '') : expr;
        // Split the expression into its parts
        const [column, filterType, value] = cleanExpr.match(/(\w+)(CONTAINS|=|!=|STARTSWITH|ENDSWITH|ISEMPTY|ISNOTEMPTY|ISANYTHING)(\w+|'.*?')/).slice(1);
  
        return {
          column,
          filterType,
          value: value.replace(/'/g, ''), // Remove any single quotes around the value
          operator: isOrOperator ? 'OR' : 'AND'
        };
      });
  
      // Update the state with the initial filters
      setFilters(initialFilters);
    }
  }, [location.search]); // Run only when the location.search changes

  useEffect(() => {
    setIsFilterVisible(false);
  }, [location.pathname]);
  

  return (
    <div className="p-4 bg-gray-50">
      <div className="flex justify-start mb-3 items-center">
        <button
          onClick={() => setIsFilterVisible(!isFilterVisible)}
          className="text-sm font-medium bg-blue-500 hover:bg-blue-700 text-white py-1.5 px-2 mr-2 rounded inline-flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
          </svg>
          <span className="ml-1">Filters</span>
        </button>
        {renderFilterChips()}
      </div>
      {isFilterVisible && (
        <div className="p-4 bg-gray-50">
          {filters.map((filter, index) => (
            <div key={index} className="flex gap-3 mb-2 items-center">
              <select
                value={filter.column}
                onChange={(e) => handleChange(index, "column", e.target.value)}
                className="w-56 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              >
                <option value="">Select Column</option>
                {columns.map((column) => (
                  <option key={column} value={column}>
                    {column}
                  </option>
                ))}
              </select>
              <select
                value={filter.filterType}
                onChange={(e) =>
                  handleChange(index, "filterType", e.target.value)
                }
                className="w-45 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              >
                <option value="">Select Filter</option>
                {FilterTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
              <input
                className="w-60 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                value={filter.value}
                onChange={(e) => handleChange(index, "value", e.target.value)}
              />
            </div>
          ))}
          <div className="flex gap-3 justify-start mt-3">
            <div className="flex items-center">
            <button
              className="py-1.5 px-4 mr-2 bg-red-500 hover:bg-red-700 text-white font-semibold rounded shadow-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-75"
              onClick={handleClearFilter}
            >
              Clear
            </button>
            <button
              className="py-1.5 px-4 mr-2 bg-green-500 hover:bg-green-700 text-white font-semibold rounded shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-75"
              onClick={handleApplyFilters}
            >
              Apply
            </button>
            <button
              className="py-1.5 px-4 mr-2 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75"
              onClick={handleAddFilter}
            >
              Add
            </button>
            
              <label className="inline-flex items-center ml-4">
                <input
                  type="radio"
                  name="filterLogic"
                  value="AND"
                  checked={nextFilterLogic === "AND"}
                  onChange={() => setNextFilterLogic("AND")}
                />
                <span className="ml-2">AND</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  name="filterLogic"
                  value="OR"
                  checked={nextFilterLogic === "OR"}
                  onChange={() => setNextFilterLogic("OR")}
                />
                <span className="ml-2">OR</span>
              </label>
            </div>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default PulseListFilter;
