import React from "react";
import Menu from "./components/Menu";

const SideBar = ({ isCollapsed, onToggleSidebar }) => {
    return (
        <div
    className={`${
        isCollapsed ? "w-12" : "w-64"
    } bg-gray-800 shadow-md h-full text-gray-300 transition-all duration-300 ease-in-out flex flex-col min-h-0`}
>
    {/* Pass down isCollapsed and onToggleSidebar to Menu */}
    <Menu isCollapsed={isCollapsed} onToggleSidebar={onToggleSidebar} />
</div>
    );
};

export default SideBar;
