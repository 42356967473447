(function(global) {
    let currentDoc = null;

    function setCurrentDoc(newDoc) {
        currentDoc = newDoc;
        global.notifyDocChanged(newDoc);
    }

    function getCurrentDoc() {
        return currentDoc;
    }

    global.PULSE = {
        setCurrentDoc,
        getCurrentDoc
    };
})(window);