import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";

import PulseFormList from "../components/MainView/form/PulseFormListView";
import FormScript from '../components/MainView/form/PulseFormScript';
import {useAuth} from "../auth/PulseAuthContext";

const PulseFormContext = createContext();

export const PulseFormProvider = ({ children}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({}); // Holds the form data
  const [formStructure, setFormStructure] = useState([]); // Holds the form structure
  const [formActions, setFormActions] = useState([]); // Holds the form actions
  const [messages, setMessages] = useState([]); // Holds form messages (errors, info, etc.)
  const { collectionName } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const view = queryParams.get("view") || "default";
  const [id, setID] = useState(queryParams.get("id"));
  const {fetchData, postData, user } = useAuth();

  /**
   * 
   * Begin the implementation of the following functions:
   * - setValue
   * - getValue
   * - clearValue
   * - clearForm
   * - addErrorMessage
   * - saveForm
   * 
   * These functions will be used to manage the form data and messages.
   */

  const executeScriptServer = (action, p_parm_id) => {
    try {
      console.log("Executing script:", action.script);
      if (!action.script || !action) {
        console.error("Action or Script is empty");
        return;
      }

      let url = "/http";
      url += '?p_parm_col=' + encodeURIComponent(collectionName);
      url += '&p_parm_id=' + encodeURIComponent(p_parm_id);
      url += '&p_parm_ui_action_id=' + encodeURIComponent(action.systemID);
      url += '&p_parm_type=PROCESS_CODE';

      postData(url, getChangedFields(), {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then((response) => {
            console.log("Response:", response.data);
          })
          .catch((error) => {
            console.error("Failed to execute script:", error);
          });
    } catch (error) {
      console.error("Failed to execute script:", error);
    }
  };

  const getChangedFields = () => {
    return formData;
  }

  const setValue = (fieldName, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: value }));
  };

  const getValue = (fieldName) => formData[fieldName];

  const clearValue = (fieldName) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      delete newFormData[fieldName];
      return newFormData;
    });
  };

  const clearForm = () => setFormData({});

  // Messages management as previously described
  const addErrorMessage = (message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "error", text: message },
    ]);
  };

  const saveForm = async () => {
    // Save form data
    console.log("Saving form data:", formData);
    try {
        const response = await axios.post(`http://localhost:3004/pulse/api/v1/form/save/${collectionName}?id=${id}`, formData);
        if(response.status === 201){
            navigate(`/form/${collectionName}?id=${response.data.systemID}&view=${view}`);
            setID(response.data.systemID);
            fetchFormData();
        }
    } catch (error) {
        console.error('Failed to save form data:', error);
    }

  }

  // End of the implementation of the functions

  const fetchActions = useCallback(async () => {
    console.log("Fetching actions:", fetchData);
    try {
      const response = await fetchData(
        `/action/${collectionName}?view=${view}`
      );

      const actions = response.data;
      console.log(actions)
      const sortedActions = actions.data.sort(
        (a, b) => a.order - b.order
      );
      setFormActions(sortedActions);
    } catch (error) {
      console.error("Failed to fetch form actions:", error);
    }
  }, [collectionName, fetchData, view]);

  const fetchFormLayout = useCallback(async () => {
    try {
      const response = await fetchData(
        `/form/layout/${collectionName}?view=${view}`
      );
      setFormStructure(response.data.data);
    } catch (error) {
      console.error("Failed to fetch form layout:", error);
    }
  }, [collectionName, fetchData, view]);

  const fetchFormData = useCallback(async () => {
    try {
      const response = await fetchData(
        `/form/data/${collectionName}?id=${id}`
      );
      setFormData(response.data.data[0]);
      const doc = response.data.data[0];
      doc.insert = async function () {
        console.log("Inserting document:", doc);
      };
    //   setCurrentDoc(doc);
    } catch (error) {
      console.error("Failed to fetch form data:", error);
    }
  }, [collectionName, fetchData, id]);

  /**
   * Fetch form layout, form actions, and form data when the component mounts.
   */
  useEffect(() => {
    fetchFormLayout();
    fetchActions();
    if (Number(id) !== -1){
      fetchFormData();
    }else{
      setFormData({});
    }
  }, [id, fetchFormData, fetchFormLayout, fetchActions]);

  const organizeFieldsIntoColumns = (fields, columns) => {
    const cols = Array.from({ length: columns }, () => []);
    fields.forEach((field) => {
      if (field.column && field.position) {
        // Ensure the target column array is long enough to include the position, filling missing items with null.
        while (cols[field.column - 1].length < field.position) {
          cols[field.column - 1].push(null);
        }
        // Place the field in the correct position, accounting for array index starting at 0.
        cols[field.column - 1][field.position - 1] = field;
      }
    });
    return cols;
  };

  const renderField = (field) => {
    if (!field) return <div className="h-8"></div>;
    const fieldValue = formData?.[field.field] ?? "";
    const baseClass =
      "px-4 py-2 border border-gray-400 rounded-md hover:bg-gray-200 focus:outline-none focus:bg-white focus:ring-2 focus:ring-inset focus:ring-gray-700 ";

    const handleEditorChange = (newValue) => {
      setFormData((prevState) => ({
        ...prevState,
        script: newValue,
      }));
    };

    const handleInputChange = (e) => {
      const { name, type, value, checked } = e.target;

      if (type === "checkbox") {
        setFormData((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    };

    if (field.field === "embeddedTable" && Number(id) !== -1) {
      return (
        <div className="mt-2">
          <PulseFormList form={formData} />
        </div>
      );
    }

    switch (field.type) {
      case "text":
      case "string":
      case "number":
        if (
          field.field === "systemID" ||
          field.field === "createdBy" ||
          field.field === "updatedBy"
        ) {
          const dtClass = `px-4 py-2 border border-gray-400 rounded-md hover:bg-gray-200 focus:outline-none  w-full focus:bg-gray-200 bg-gray-200`;
          return (
            <input
              type={field.type}
              name={field.field}
              value={fieldValue}
              placeholder={field.label}
              className={`${dtClass} cursor-not-allowed`}
              readOnly
            />
          );
        } else {
          return (
            <input
              type={field.type}
              name={field.field}
              value={fieldValue}
              onChange={handleInputChange}
              placeholder={field.label}
              className={`${baseClass} font-normal w-full`}
            />
          );
        }
      case "email":
        return (
          <input
            type="email"
            name={field.field}
            value={fieldValue}
            onChange={handleInputChange}
            placeholder={field.label}
            className={`${baseClass} w-full`}
          />
        );
      case "textarea":
        return (
          <textarea
            name={field.field}
            value={fieldValue}
            onChange={handleInputChange}
            placeholder={field.label}
            className={`${baseClass} w-full`}
          ></textarea>
        );
      case "password":
        return (
          <input
            type="password"
            name={field.field}
            value={fieldValue}
            onChange={handleInputChange}
            placeholder={field.label}
            className={`${baseClass} w-full`}
          />
        );
      case "script":
        return <FormScript code={fieldValue} onChange={handleEditorChange} />;
      case "boolean":
        return (
          <div className="flex flex-col items-start py-2.5 px-2.5">
            <label
              className="relative flex items-center rounded-full cursor-pointer"
              htmlFor="checkbox"
            >
              <input
                type="checkbox"
                name={field.field}
                checked={!!fieldValue}
                onChange={handleInputChange}
                className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-gray-700 before:opacity-0 before:transition-opacity checked:border-gray-700 checked:bg-gray-700 checked:before:bg-gray-700 hover:before:opacity-10"
                id="checkbox"
              />
              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
          </div>
        );
      case "datetime":
        const dtClass = `px-4 py-2 border border-gray-400 rounded-md hover:bg-gray-200 focus:outline-none  w-full focus:bg-gray-200 bg-gray-200`;
        if (field.field === "createdAt" || field.field === "updatedAt") {
          let formattedDateTime = "";

          const dateTime = new Date(fieldValue);
          if (!isNaN(dateTime)) {
            formattedDateTime = dateTime.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });
          }

          return (
            <input
              type="text"
              name={field.field}
              value={formattedDateTime}
              onChange={handleInputChange}
              placeholder={field.label}
              className={`${dtClass} cursor-not-allowed`}
              readOnly
            />
          );
        } else {
          return (
            <input
              type="text"
              name={field.field}
              value={fieldValue}
              onChange={handleInputChange}
              placeholder={field.label}
              className={dtClass}
            />
          );
        }
      default:
        return null;
    }
  };

  const renderSectionHeader = (label) => {
    return (
      <h2 className="text-lg font-semibold text-gray-700 pt-4 pb-2 px-4">
        {label}
      </h2>
    );
  };

  const renderSection = (section) => {
    const columns = organizeFieldsIntoColumns(section.fields, section.columns);

    const gridClassMap = {
      1: "grid-cols-1",
      2: "grid-cols-2",
      3: "grid-cols-3",
    };
    const gridClass = `grid ${gridClassMap[section.columns]} gap-4 p-4`;

    return (
      <div
        key={section.systemID}
        className="flex flex-col bg-white shadow overflow-hidden mb-4 rounder-lg"
      >
        {section.label && renderSectionHeader(section.label)}
        <div className={`${gridClass} ${!section.label ? "rounded-t-lg" : ""}`}>
          {columns.map((col, colIndex) => (
            <div key={colIndex} className="flex flex-col space-y-1">
              {col.map((field, fieldIndex) =>
                field ? (
                  <div key={field.label}>
                    <label
                      htmlFor={field.label}
                      className="text-sm font-semibold text-gray-700"
                    >
                      {field.label}
                    </label>
                    {renderField(field)}
                  </div>
                ) : null
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const providerValue = {
    setValue,
    getValue,
    saveForm,
    clearValue,
    clearForm,
    addErrorMessage,
    formStructure,
    renderSection,
    formActions,
    executeScriptServer
  };

  return (
    <PulseFormContext.Provider value={providerValue}>
      {children}
    </PulseFormContext.Provider>
  );
};

export const usePulseForm = () => useContext(PulseFormContext);