import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../auth/PulseAuthContext";
import { FiUser, FiSettings, FiGrid } from "react-icons/fi";
import { MdOutlineManageAccounts } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";

const TopBar = () => {
    const { logout, user } = useAuth();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isAppMenuOpen, setIsAppMenuOpen] = useState(false);
    const dropdownRef = useRef(null);
    const appMenuRef = useRef(null);

    const handleLogout = () => {
        logout();
    };

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
            if (appMenuRef.current && !appMenuRef.current.contains(event.target)) {
                setIsAppMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="bg-gradient-to-br from-[#1E215D] via-[#3B185F] to-[#6E44FF] h-16 flex items-center justify-between px-5 shadow-lg">
            <div className="text-white text-4xl font-semibold">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#F97316] to-[#E879F9]">Pulse</span>
            </div>
            <div className="flex items-center space-x-4">
                {/* App Menu Button */}
                <div className="relative" ref={appMenuRef}>
                    <button
                        onClick={() => setIsAppMenuOpen(!isAppMenuOpen)}
                        className="flex items-center justify-center w-10 h-10 bg-gradient-to-r from-[#F97316] to-[#E879F9] rounded-full text-white focus:outline-none focus:ring-2 focus:ring-[#F97316] transition-all duration-300 ease-out transform hover:scale-110 hover:shadow-[0_0_15px_rgba(249,115,22,0.6)]"
                    >
                        <FiGrid size={24} />
                    </button>
                    {isAppMenuOpen && (
                        <div className="absolute right-0 mt-2 w-80 bg-[#1F2133] text-white rounded-xl shadow-lg z-50 overflow-hidden p-4 transform transition-transform duration-300 ease-out scale-100 origin-top-right">
                            <div className="grid grid-cols-3 gap-4">
                                {Array.from({ length: 9 }).map((_, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-col items-center justify-center bg-gradient-to-br from-[#2B2D42] to-[#3B3E58] w-20 h-20 rounded-md hover:scale-105 hover:bg-gradient-to-r from-[#6E44FF] to-[#3B82F6] hover:shadow-[0_0_10px_rgba(110,68,255,0.4)] transition-all duration-300 ease-in-out cursor-pointer opacity-0 animate-slideIn"
                                        style={{ animationDelay: `${index * 0.05}s` }}
                                    >
                                        <div className="w-10 h-10 bg-gray-500 rounded-full mb-2"></div>
                                        <div className="text-sm text-gray-300">App {index + 1}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {/* User Menu Button */}
                <div className="relative" ref={dropdownRef}>
                    <button
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        className="flex items-center justify-center w-10 h-10 bg-gradient-to-r from-[#F97316] to-[#E879F9] rounded-full text-white focus:outline-none focus:ring-2 focus:ring-[#F97316] transition-all duration-300 ease-out transform hover:scale-110 hover:shadow-[0_0_15px_rgba(249,115,22,0.6)]"
                    >
                        <FiUser size={24} />
                    </button>
                    {isDropdownOpen && (
                        <div className="absolute right-0 mt-2 w-60 bg-[#1F2133] text-white rounded-xl shadow-lg z-50 overflow-hidden transform transition-transform duration-300 ease-out scale-95 origin-top-right">
                            <div className="flex items-center px-4 py-2 bg-gradient-to-br from-[#1E215D] to-[#2B2D42] rounded-t-xl">
                                <FiUser size={36} className="mr-3" />
                                <div>
                                    <div className="font-semibold text-gray-300">{user || "User"}</div>
                                    <div className="text-sm text-gray-500">user@example.com</div>
                                </div>
                            </div>

                            <ul className="bg-[#1F2133] rounded-b-xl">
                                <div className="my-2">
                                    <li className="mx-3 px-4 py-1 hover:bg-gradient-to-r from-[#6E44FF] to-[#3B82F6] cursor-pointer flex items-center rounded-md transition-all duration-200 ease-in-out hover:shadow-[0_0_10px_rgba(110,68,255,0.5)]">
                                        <FiSettings className="mr-2" />
                                        <button className="w-full text-left">Preferences</button>
                                    </li>
                                </div>
                                <div className="my-2">
                                    <li className="mx-3 px-4 py-1 hover:bg-gradient-to-r from-[#6E44FF] to-[#3B82F6] cursor-pointer flex items-center rounded-md transition-all duration-200 ease-in-out hover:shadow-[0_0_10px_rgba(110,68,255,0.5)]">
                                        <MdOutlineManageAccounts className="mr-2" />
                                        <button className="w-full text-left">Manage Account</button>
                                    </li>
                                </div>
                                <hr className="border-gray-700 my-2" />
                                <div className="my-2">
                                    <li
                                        onClick={handleLogout}
                                        className="mx-3 px-4 py-1 text-red-500 hover:bg-red-700 hover:text-white cursor-pointer flex items-center rounded-md transition-all duration-200 ease-in-out transform hover:scale-105 hover:shadow-[0_0_10px_rgba(255,0,0,0.6)]"
                                    >
                                        <AiOutlineLogout className="mr-2" />
                                        Sign Out
                                    </li>
                                </div>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopBar;
