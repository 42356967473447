import React from "react";
import MonacoEditor from "react-monaco-editor";

const FormScript = ({code, onChange}) => {

  const editorDidMount = (editor, monaco) => {
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
    roundedSelection: true,
    readOnly: false,
    cursorStyle: "line",
    automaticLayout: true,
    autoIndent: true,
    codeLens: true,
    dragAndDrop: true,
    wordWrap: "on",
  };

  return (
    <div className="editor-container p-2 border border-gray-400 rounded-lg">
    <MonacoEditor
      id="editor"
      width="100%"
      height="300"
      language="javascript"
      value={code}
      onChange={onChange}
      editorDidMount={editorDidMount}
      options={options}
    />
    </div>
  );
};

export default FormScript;
