import React, {useState, useEffect, useCallback} from 'react';
import { useLocation, useParams } from "react-router-dom";
import {useAuth} from "../../../auth/PulseAuthContext";

const PulseFormList = (form) => {
  const location = useLocation();
  const { collectionName } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const view = queryParams.get('view') || 'default';
  const filter = queryParams.get('filter');
  const [checkAll, setCheckAll] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const {fetchData} = useAuth();
  
  const [columnLayout, setColumnLayout] = useState([]);
  const [listData, setListData] = useState([]);

  const handleCheckAll = (event) => {
    setCheckAll(event.target.checked);
    setCheckedState(new Array(listData.length).fill(event.target.checked));
  };

  const handleSingleCheck = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const fetchColumnLayout = useCallback(async () => {
    try {
        //need to change the url to the correct one system_registry will be replaced by the collection name
        let urlBase = '/list/header/';
        if(collectionName){
          urlBase = urlBase + 'system_registry';
        } 
        if(view){
          urlBase = urlBase + '?view=' + view;
        }

        urlBase = urlBase + '&parent=' + collectionName;
        console.log('Fetching column layout:', urlBase);
        const response = await fetchData(urlBase);
        console.log('Column layout:', response.data);
        setColumnLayout(response.data.result.listFields);
      } catch (error) {
        console.error('Failed to fetch column layout:', error);
      }
  }, [collectionName, view, fetchData]);

  const fetchListData = useCallback(async () => {
    try {
        console.log('form:', JSON.stringify(form));
        //need to change the url to the correct one system_registry will be replaced by the collection name
        let urlBase = `/list/${form.form.name}?view=${view}&parent=system_collection`;
        console.log('Fetching list data:', urlBase);
        const response = await fetchData(urlBase);
        console.log('List data:', response.data);
        setListData(response.data.data);
        setCheckedState(new Array(response.data.data.length).fill(false));
      } catch (error) {
        console.error('Failed to fetch list data:', error);
      }
  }, [form, view, fetchData]);

  useEffect(() => {
    fetchColumnLayout();
    fetchListData();
  }, [collectionName, view, filter, fetchColumnLayout, fetchListData]);

  return (
    <div className="flex flex-col h-full">
      <div className='flex justify-between p-4 bg-gray-100 border-gray-200'>
        <div>
            <button className=' bg-gray-500 hover:bg-gray-800 text-white font-bold py-1.5 px-4 rounded'>
                New
            </button>
        </div>
      </div>
      <div className="flex-grow overflow-x-auto">
        <table className="min-w-full divide-y table-fixed divide-gray-200">
        <thead className="whitespace-nowrap bg-gray-50 sticky top-0" >
            <tr>
              <th className="px-6 py-4  text-left"> 
                <input type="checkbox" className="h-5 w-5" checked={checkAll} onChange={handleCheckAll}/>
              </th>
              <th className="w-12 p-1"> {/* Empty <th> for alignment */} </th>
              {columnLayout?.map((field, index) => (
                <th
                  key={index}
                  className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider whitespace-nowrap text-sm"
                >
                  {field.label || field.field}
                </th>
              ))}
            </tr>
          <tr>
            <th className="w-12 p-1"> {/* Empty <th> for alignment */} </th>
            <th className="w-12 p-1"> {/* Empty <th> for alignment */} </th>
            {columnLayout?.map((field, index) => (
                <th key={index} className="w-12 p-1">
                  <input
                      type="text"
                      className="mt-1 block w-full border border-gray-400 hover:bg-gray-200 rounded-md py-1 px-3 focus:outline-none focus:bg-white focus:ring-2 focus:ring-inset focus:ring-gray-700 font-normal"
                      placeholder="Search"
                  />
                </th>
            ))}
          </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {listData.map((row, rowIndex) => (
              <tr key={rowIndex} className="even:bg-gray-100 hover:bg-gray-200">
                <td className="px-6 py-4 text-left">
                  <input
                      type="checkbox"
                      className="form-checkbox mt-2 h-5 w-5"
                      checked={checkedState[rowIndex]}
                      onChange={() => handleSingleCheck(rowIndex)}
                  />
                </td>
                <td className="px-1 py-4 text-left">
                  <button
                    // onClick={() => handleViewClick(row.systemID)}
                    className="bg-gray-500 hover:bg-gray-800 text-white rounded px-2 py-1 text-sm"
                  >
                    View
                  </button>
                </td>
                {columnLayout.map((col) => (
                  <td
                    key={col.field}
                    className="w-32 break-words px-6 py-4 text-sm text-gray-700 hover:bg-gray-300"
                  >
                    {typeof row[col.field] === 'boolean' ? row[col.field].toString() : row[col.field]}
                  </td>
                ))}
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Possibly pagination or other components here */}
    </div>
  );
};

export default PulseFormList;
