import React from "react";
import { usePulseForm } from "../../../utils/PFormManager";
import { useLocation } from "react-router-dom";

const PulseUIActionButton = ({ action }) => {
  const context = usePulseForm();
  const location = useLocation();
  const p_parm_id = new URLSearchParams(location.search).get("id");

  const handleClick = () => {
    if (action.client) {
      const form = {
        ...context,
        getValue: context.getValue,
        clearValue: context.clearValue,
        clearForm: context.clearForm,
        addErrorMessage: context.addErrorMessage,
        setValue: context.setValue,
        saveForm: context.saveForm,
      };
      try {
        //TODO: need to work on client side scripting
        // new Function("form", action.script)(form);
      } catch (e) {
        console.log('Error executing UI action', e);
      }
    } else {
      console.log("Executing server script via executeScriptServer method:", action.script);
      try {
        context.executeScriptServer(action, p_parm_id); // Use executeScriptServer from context to execute server-side script
      } catch (e) {
        console.log("Error executing UI action", e);
      }
    }
  };

  return (
      <button
          onClick={handleClick}
          className=" bg-gray-500 hover:bg-gray-800 text-white font-bold py-1.5 px-4 rounded"
      >
        {action.name}
      </button>
  );
};

export default PulseUIActionButton;