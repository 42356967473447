// PulseFormContextMenu.js
import React, {useState, useEffect, useRef, useCallback} from 'react';

const PulseFormContextMenu = ({ id }) => {
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef(null);

    const handleContextMenu = useCallback ((event) => {
        if(shouldShowMenu(event)){
            event.preventDefault(); // Prevent the default context menu from showing
            setPosition({ x: event.pageX, y: event.pageY });
            setVisible(true);
        }
    }, []);

    const handleClose = () => {
        setVisible(false);
    };

    const handleCopySystemID = () => {
        navigator.clipboard.writeText(id);
        handleClose();
    };

    const shouldShowMenu = (event) => {
        let element = event.target;
        while (element) {
            if (element.getAttribute && element.getAttribute && element.getAttribute('top-bar-menu-context') === 'true') {
                return true;
            }
            element = element.parentNode;
        }
        return false;
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if(menuRef.current && !menuRef.current.contains(event.target)){
                handleClose();
            }
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [handleContextMenu]); // Dependencies array

    if (!visible) return null;

    return (
        <div ref={menuRef} className="context-menu bg-white border border-gray-200 rounded-md shadow-lg z-50"
        style={{position: 'fixed', top: position.y, left: position.x }}>
            <ul className="text-sm text-gray-700">
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={handleCopySystemID}>
                    Copy System ID
                </li>
                {/* Additional menu items here */}
            </ul>
        </div>
    );
};

export default PulseFormContextMenu;
