// src/context/PulseAuthContext.js
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo
} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import PulseSessionExpiredModal from "./PulseSessionExpiredModal";

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  // Provide a warning or throw an error if these functions are called outside AuthProvider
  login: async () => {
    console.warn("AuthContext: login function called without an AuthProvider. This is a no-op.");
    return false; // return some fallback value
  },
  logout: () => {
    console.warn("AuthContext: logout function called without an AuthProvider. This is a no-op.");
  },
  error: null,
  fetchData: async () => {
    console.warn("AuthContext: fetchData function called without an AuthProvider. This is a no-op.");
    throw new Error("No AuthProvider found. Cannot fetch data.");
  },
  postData: async () => {
    console.warn("AuthContext: postData function called without an AuthProvider. This is a no-op.");
    throw new Error("No AuthProvider found. Cannot post data.");
  },
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  // Initialize user from localStorage if it exists
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('pulseUser');
    return savedUser && savedUser !== "undefined" ? JSON.parse(savedUser) : null;
  });

  const [error, setError] = useState(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Close the session expired modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle session expiration:
  // Clear user data, open modal, redirect to /login
  const handleSessionExpired = useCallback(() => {
    setUser(null);
    localStorage.removeItem('pulseUser');
    setIsModalOpen(true);
    navigate('/login', { replace: true }); // Redirect user to login page
  }, [navigate]);

  // Create a memoized axios instance so it's not recreated on every render
  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL || window.location.origin}/pulse/api/v1`,
      withCredentials: true
    });

    // Request interceptor:
    // Before any request that requires an active session,
    // we verify the session is valid. If not valid (401),
    // we handle session expiration.
    instance.interceptors.request.use(
        async (config) => {
          const skipAuthEndpoints = [
            '/auth?requestType=login',
            '/auth?requestType=logout',
          ];

          // If we are logging out, the user doesn't exist, or the request endpoint is skipped,
          // we skip session checks.
          if (isLoggingOut || !user || skipAuthEndpoints.some((endpoint) => config.url.includes(endpoint))) {
            return config;
          }

          // Check session before making the actual request
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL || window.location.origin}/pulse/api/v1/auth?requestType=session`, {
              withCredentials: true,
            });

            // If session check is successful, proceed with the request
            if (response.status === 200) {
              return config;
            }
          } catch (error) {
            // If we get a 401 during session check, the session is expired
            if (error.response && error.response.status === 401) {
              handleSessionExpired();
            }
            // Reject the request since session is not valid
            return Promise.reject(error);
          }
        },
        (error) => Promise.reject(error)
    );

    // Response interceptor:
    // If any response (after passing session check) returns a 401 or 403,
    // consider the session expired and handle it, except if it was a login attempt.
    instance.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            const requestedUrl = error.response.config.url;

            // If not logging out and not a login request (invalid credentials),
            // the session must have expired, so handle accordingly.
            if (!isLoggingOut && !requestedUrl.includes('/auth?requestType=login')) {
              handleSessionExpired();
            }
          }
          return Promise.reject(error);
        }
    );

    return instance;
  }, [isLoggingOut, user, handleSessionExpired]);

  // Handle user login
  // If credentials are wrong, set an error message but do not show the modal.
  const login = async (username, password) => {
    setError(null);
    try {
      const response = await axiosInstance.post('/auth?requestType=login', {
        "action": "login",
        "fields": {
          username,
          password,
        }
      });

      // If login successful, store the username in state and localStorage
      if (response.status === 200 && response.data.username) {
        setUser(response.data.username);
        localStorage.setItem('pulseUser', JSON.stringify(response.data.username));
        return true;
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        // Invalid credentials
        setError('Username or password is incorrect');
      } else {
        // Other error
        setError('An error occurred. Please try again.');
      }
      return false;
    }
  };

  // Handle user logout
  // After logging out, clear user and redirect to /login without showing the modal.
  const logout = useCallback(() => {
    if (isLoggingOut) return;
    setIsLoggingOut(true);

    axiosInstance.post('/auth?requestType=logout')
        .catch((error) => {
          console.error('Logout Error:', error);
        })
        .finally(() => {
          setUser(null);
          setError(null);
          localStorage.removeItem('pulseUser');
          setIsLoggingOut(false);
          navigate('/login', { replace: true }); // Redirect to login
        });
  }, [navigate, axiosInstance, isLoggingOut]);

  // Fetch data from a protected endpoint
  // Session check happens in interceptors before this request goes out.
  const fetchData = async (endpoint) => {
    if (!endpoint) {
      console.error('fetchData called with no endpoint!');
      console.trace('Trace for missing endpoint');
      throw new Error('Endpoint must be provided to fetchData');
    }
    try {
      return await axiosInstance.get(endpoint);
    } catch (error) {
      console.error('Fetch data error:', error);
      throw error;
    }
  };

  // Post data to a protected endpoint
  // Session check happens in interceptors before this request goes out.
  const postData = async (endpoint, data) => {
    if (!endpoint) {
      console.error('postData called with no endpoint!');
      throw new Error('Endpoint must be provided to postData');
    }
    try {
      return await axiosInstance.post(endpoint, data);
    } catch (e) {
      console.error("Post data error: ", e);
      throw e;
    }
  };

  // Auth context values that components can use
  const value = {
    isAuthenticated: !!user,
    user,
    login,
    logout,
    error,
    fetchData,
    postData
  };

  return (
      <AuthContext.Provider value={value}>
        {children}
        {/* Modal displayed when session expires */}
        <PulseSessionExpiredModal isOpen={isModalOpen} onClose={closeModal} />
      </AuthContext.Provider>
  );
};