import React from "react";
import {useAuth} from "../../auth/PulseAuthContext";
import {useNavigate} from "react-router-dom";

const PulseLogin = () => {

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const {login, error} = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        console.log("Logging in with username " + username);
        e.preventDefault();
        const loginSuccess = await login(username, password);

        if (loginSuccess) {
            navigate("/");
        }
    }

    const isDisabled = !username || !password;

    return (
        <div className="flex items-center justify-center h-screen bg-gradient-to-br from-[#1E215D] via-[#3B185F] to-[#6E44FF]">
            <div className="flex flex-col items-center w-full max-w-md p-8 text-center">
                <h1 className="text-4xl font-semiboldbold text-white mb-8">
                    Welcome to <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#F97316] to-[#E879F9]">Pulse</span>
                </h1>
                {error && <div className="p-2 mb-4 text-white bg-red-500 rounded-lg">{error}</div>}
                <form onSubmit={handleSubmit} className="w-full">
                    <div className="mb-6">
                        <input type="text" value={username} onChange={e => setUsername(e.target.value)}
                               placeholder="Username"
                               className="w-full px-4 py-3 text-lg text-white placeholder-gray-400 bg-[#1F2133] border border-[#4C4F64] rounded-lg focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#F97316]"/>
                    </div>
                    <div className="mb-6">
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)}
                               placeholder="Password"
                               className="w-full px-4 py-3 text-lg text-white placeholder-gray-400 bg-[#1F2133] border border-[#4C4F64] rounded-lg focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#F97316]"/>
                    </div>
                    <button type="submit" disabled={isDisabled}
                            className={`w-full py-3 text-lg font-medium rounded-lg transition-all transform duration-300 ${
                                isDisabled
                                    ? 'bg-gradient-to-r from-gray-600 to-gray-800 opacity-50 text-gray-300 cursor-not-allowed'
                                    : `bg-gradient-to-r from-[#F97316] to-[#E879F9] text-white hover:scale-105 focus:ring-2 focus:ring-inset focus:ring-[#F97316] opacity-100 ${
                                        error ? 'animate-shake' : ''
                                    }`
                            }`}
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
}

export default PulseLogin;