// MainView.js
import React from "react";
import { Outlet } from "react-router-dom";

const MainView = () => {

  return (
    <div className="flex-1 h-full overflow-x-auto">
        <Outlet />
    </div>
  );
};

export default MainView;
