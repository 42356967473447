// Import necessary libraries and dependencies
import React, {useEffect, useRef, useState} from 'react';
import Fuse from 'fuse.js';
import {FiX} from "react-icons/fi";

const LogsDisplay = () => {
    const [logs, setLogs] = useState([]);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [connectionError, setConnectionError] = useState(false);
    const [filterLevel, setFilterLevel] = useState('ALL');
    const logsEndRef = useRef(null);
    const logsContainerRef = useRef(null);
    const [filterStartTime, setFilterStartTime] = useState('');
    const [filterEndTime, setFilterEndTime] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    function highlightKeywords(message) {
        if (!searchKeyword) return message; // No highlighting if no keyword is entered

        // Escape special characters in the search keyword to safely use in a regex
        const escapedKeyword = searchKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const keywordRegex = new RegExp(`(${escapedKeyword})`, 'gi');

        // Wrap matched keyword in a styled <span> for highlighting
        return message.replace(
            keywordRegex,
            '<span class="bg-yellow-200 font-bold text-red-600">$1</span>'
        );
    }

    useEffect(() => {
        let eventSource;
        let retryTimeout;

        const connectEventSource = () => {
            // Establish an EventSource connection to the backend
            eventSource = new EventSource(window.location.origin + '/pulse/api/v1/stream/logs');

            // Set up EventSource listeners
            eventSource.onmessage = (event) => {
                try{
                    setConnectionError(false); // Reset connection error on successful message receipt
                    const parsedMessage = JSON.parse(event.data);
                    setLogs((prevLogs) => {
                        // Limit logs to prevent memory issues
                        const maxLogs = 1000;
                        const updatedLogs = [...prevLogs, parsedMessage];
                        return updatedLogs.length > maxLogs ? updatedLogs.slice(-maxLogs) : updatedLogs;
                    });
                }catch (e){
                    console.error('Error processing logs: ',e);
                }

            };

            eventSource.onerror = () => {
                setConnectionError(true); // Set error state when connection fails
                eventSource.close();
                // Retry connection after a delay
                retryTimeout = setTimeout(() => {
                    connectEventSource();
                }, 5000);
            };
        };

        connectEventSource();

        // Clean up EventSource connection on component unmount
        return () => {
            if (eventSource) {
                eventSource.close();
            }
            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
        };
    }, []);

    useEffect(() => {
        // Scroll to the bottom whenever logs are updated if user is at the bottom
        if (isAtBottom && logsEndRef.current) {
            logsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [logs, isAtBottom]);

    const handleScroll = () => {
        if (logsContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = logsContainerRef.current;
            setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 10);
        }
    };

    const scrollToBottom = () => {
        if (logsEndRef.current) {
            logsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const clearEvents = () =>{
        setLogs([]);
        setFilterEndTime('');
        setFilterStartTime('');
        setFilterLevel('ALL');
        setSearchKeyword('');
    }

    const getLogLevelStyle = (level) => {
        switch (level) {
            case 'ERROR':
                return 'bg-red-500 text-red-200';
            case 'INFO':
                return 'bg-blue-500 text-blue-200';
            case 'WARN':
                return 'bg-yellow-500 text-yellow-700';
            case 'DEBUG':
                return 'bg-gray-800 text-gray-400';
            case 'SILLY':
                return 'bg-green-500 text-green-200';
            default:
                return 'bg-gray-500 text-gray-300';
        }
    };

    const fuseOptions = {
        keys: ['message', 'location', 'timestamp'],
        threshold: 0.5,
        useExtendedSearch: true,
    };

    const fuse = new Fuse(logs, fuseOptions);

    const filteredLogs = (() => {
        let filteredLogs = logs;

        if(searchKeyword) {
            filteredLogs = fuse.search(searchKeyword).map((result) => result.item);
        }

        return filteredLogs.filter((log) => {
            if(filterLevel !== 'ALL' && log.level !== filterLevel) return false;
            if(filterStartTime && new Date(log.timestamp) < new Date(filterStartTime)) return false;
            if(filterEndTime && new Date(log.timestamp) > new Date(filterEndTime)) return false;
            return true;
        });
    })();

    return (
        <div className="p-4 h-full flex flex-col">
            <h2 className="text-lg font-bold mb-4">Server Logs</h2>
            {connectionError && (
                <div className="text-red-500 mb-4">Connection to server lost. Attempting to reconnect...</div>
            )}
            <div className="mb-2">
                <label htmlFor="logLevelFilter" className="mr-2 font-semibold">Level:</label>
                <select
                    id="logLevelFilter"
                    value={filterLevel}
                    onChange={(e) => setFilterLevel(e.target.value)}
                    className="border border-gray-400 rounded-md hover:bg-gray-200 focus:outline-none focus:bg-white focus:ring-2 focus:ring-inset focus:ring-gray-700 font-normal py-1 px-3 mr-4"
                >
                    <option value="ALL">All</option>
                    <option value="ERROR">Error</option>
                    <option value="WARN">Warning</option>
                    <option value="INFO">Info</option>
                    <option value="DEBUG">Debug</option>
                    <option value="TRACE">Trace</option>
                </select>
                <label htmlFor="startTimeFilter" className="mr-2 font-semibold">Start Time:</label>
                <input
                    type="datetime-local"
                    step="1"
                    id="startTimeFilter"
                    value={filterStartTime}
                    onChange={(e) => setFilterStartTime(e.target.value)}
                    className="border border-gray-400 rounded-md hover:bg-gray-200 focus:outline-none focus:bg-white focus:ring-2 focus:ring-inset focus:ring-gray-700 font-normal py-1 px-3 mr-4"
                />
                <label htmlFor="endTimeFilter" className="mr-2 font-semibold">End Time:</label>
                <input
                    type="datetime-local"
                    step="1"
                    id="endTimeFilter"
                    value={filterEndTime}
                    onChange={(e) => setFilterEndTime(e.target.value)}
                    className="border border-gray-400 rounded-md hover:bg-gray-200 focus:outline-none focus:bg-white focus:ring-2 focus:ring-inset focus:ring-gray-700 font-normal py-1 px-3 mr-4"
                />
                <button
                    className="py-1 px-2 bg-red-500 hover:bg-red-700 text-white font-semibold rounded shadow-md focus:outline-none "
                    onClick={clearEvents}>Clear
                </button>
            </div>
            <div className="mb-2 mt-2 relative">
                <input
                    type="text"
                    id="searchLogs"
                    placeholder="Search logs"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    className="mt-1 block w-full border border-gray-400 rounded-md py-2 px-3 pr-10 focus:outline-none focus:bg-white focus:ring-2 focus:ring-inset focus:ring-gray-700 font-normal"
                />
                {searchKeyword && (
                    <button
                        onClick={() => setSearchKeyword('')}
                        className="absolute right-3 top-1/2 transform -translate-y-[45%] bg-gray-200 text-gray-600 rounded-full w-6 h-6 flex items-center justify-center hover:bg-gray-300"
                        title="Clear Search"
                    >
                        <FiX size={18} />
                    </button>
                )}
            </div>
            <div
                ref={logsContainerRef}
                onScroll={handleScroll}
                className="flex-1 overflow-auto border border-gray-300 rounded-lg p-2 bg-gray-100 relative"
            >
                {filteredLogs.length === 0 ? (
                    <p>No logs to display</p>
                ) : (
                    filteredLogs.map((log, index) => (
                        <div key={index} className="mb-2 p-2 border border-gray-200 rounded-md bg-white shadow-md">
                            <div className="flex justify-between items-center">
                                <span
                                    className="mt-0.5 text-gray-800"><strong>Timestamp:</strong> {log.timestamp}</span>
                                <span
                                    className={`px-2 py-1 text-xs rounded ${getLogLevelStyle(log.level)}`}>{log.level}</span>
                            </div>
                            <p className="mt-0.5 text-gray-800"><strong>Location:</strong> {log.location}</p>
                            <p
                                className="mt-0.5 text-gray-800"
                                dangerouslySetInnerHTML={{
                                    __html: `<strong>Message:</strong> ${highlightKeywords(log.message)}`,
                                }}
                            />
                        </div>
                    ))
                )}
                <div ref={logsEndRef}></div>
                {!isAtBottom && (
                    <div
                        onClick={scrollToBottom}
                        className="fixed bottom-16 right-8 bg-blue-500 text-white p-3 rounded-full cursor-pointer shadow-lg flex items-center justify-center"
                        style={{transform: 'translateY(50%)', zIndex: 1000}}
                        title="Scroll to Bottom"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LogsDisplay;
