import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../auth/PulseAuthContext";
import { FiMenu, FiSearch, FiX } from "react-icons/fi";

const transformMenuStructure = (items) => {
    return items.map((item) => ({
        systemID: item.systemID,
        title: item.label,
        link: item.link,
        hint: item.hint,
        children: item.modules ? transformMenuStructure(item.modules) : [],
    }));
};

// Function to recursively filter menu items based on search query
const filterMenuItems = (items, query) => {
    if (!query) return items;

    return items
        .map((item) => {
            // Check if the current item matches the query
            const isMatch = item.title.toLowerCase().includes(query.toLowerCase());

            // Recursively filter children if they exist
            const filteredChildren = item.children ? filterMenuItems(item.children, query) : [];

            // Return the item if it matches or if any of its children match
            if (isMatch || filteredChildren.length > 0) {
                return {
                    ...item,
                    children: filteredChildren,
                };
            }

            // Otherwise, do not include this item
            return null;
        })
        .filter(Boolean); // Remove any null values
};

const renderMenuItems = (items, isCollapsed) =>
    items.map((item) => (
        <div key={item.systemID} className="my-1">
            <Link
                to={item.link || "#"}
                title={item.hint || "No hint available"}
                className={`flex items-center py-2 px-3 text-gray-300 hover:text-[#6E44FF] hover:bg-gray-800 rounded-md transition-all duration-200 ease-in-out block`}
            >

                {/* Only show item title when sidebar is not collapsed */}
                {!isCollapsed && <span>{item.title}</span>}
            </Link>
            {item.children && item.children.length > 0 && (
                <div className={`ml-4 border-l border-gray-700 pl-4 mt-1 ${isCollapsed ? "hidden" : ""}`}>
                    {renderMenuItems(item.children, isCollapsed)}
                </div>
            )}
        </div>
    ));

const Menu = ({ isCollapsed, onToggleSidebar }) => {
    const [menuStructure, setMenuStructure] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const { fetchData } = useAuth();

    useEffect(() => {
        const getMenuData = async () => {
            try {
                const response = await fetchData("/menu");
                console.log("Menu Response:", response.data.data);
                const transformedData = transformMenuStructure(response.data.data);
                setMenuStructure(transformedData);
            } catch (e) {
                console.error(e);
            }
        };
        getMenuData();
    }, [fetchData]);

    // Filtering the menu structure based on search query (recursively)
    const filteredMenuStructure = filterMenuItems(menuStructure, searchQuery);

    const clearSearch = () => {
        setSearchQuery("");
    }

    return (
        <div className="flex flex-col flex-1 min-h-0">
            {isCollapsed ? (
                // Center the collapse button when collapsed
                <div className="flex items-center justify-center flex-shrink-0 h-16">
                    <button
                        className="text-white focus:outline-none"
                        onClick={onToggleSidebar}
                    >
                        <FiMenu size={24} />
                    </button>
                </div>
            ) : (
                // Show collapse button and search input when not collapsed
                <div className="flex items-center ml-1 mb-2 mt-3 flex-shrink-0 px-2">
                    <button
                        className="text-white focus:outline-none"
                        onClick={onToggleSidebar}
                    >
                        <FiMenu size={24} />
                    </button>
                    <div className="relative flex-1 ml-2">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full py-2 pl-10 pr-10 bg-gray-800 text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#6E44FF] transition-all duration-200 ease-in-out"
                        />
                        <FiSearch
                            size={18}
                            className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400"
                        />
                        {searchQuery && (
                            <button
                                className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 hover:text-white focus:outline-none"
                                onClick={clearSearch}
                            >
                                <FiX size={18} />
                            </button>
                        )}
                    </div>
                </div>
            )}
            {/* Menu Items Section */}
            <div className="flex-1 overflow-y-auto pr-1 sidebar-scrollbar">
                {renderMenuItems(filteredMenuStructure, isCollapsed)}
            </div>
        </div>
    );
};

export default Menu;
