import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

const PulseSessionExpiredModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 text-center">
                {/* Exclamation Icon */}
                <div className="flex justify-center mb-4">
                    <FaExclamationCircle className="text-yellow-500 text-5xl" />
                </div>
                <h2 className="text-xl font-semibold text-gray-800">Session Expired</h2>
                <p className="mt-4 text-gray-600">
                    Your session has expired. Please login again.
                </p>
                <div className="mt-6">
                    <button
                        onClick={onClose}
                        className="w-full py-3 text-lg font-medium rounded-lg transition-all transform duration-300 bg-gradient-to-r from-[#F97316] to-[#E879F9] text-white hover:scale-105 focus:ring-2 focus:ring-inset focus:ring-[#F97316] opacity-100"
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PulseSessionExpiredModal;