import React from 'react';

const ContextMenu = React.forwardRef(({ visible, position, onClose, onRefresh }, ref) => {
  if (!visible) return null;
  
  return (
    <div
      ref={ref}
      className="bg-white border border-gray-200 rounded-md shadow-lg z-50"
      style={{position: 'fixed', top: position.y, left: position.x }}
    >
      <ul className="text-sm text-gray-700">
        <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={onRefresh}>Refresh</li>
        {/* Additional menu items here */}
      </ul>
    </div>
  );
});

export default ContextMenu;