import React, { useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { useAuth } from "./auth/PulseAuthContext";
import Login from "./components/Login/PulseLogin";
import TopBar from "./components/TopBar/PulseTopBar";
import SideBar from "./components/SideBar/PulseSideBar";
import MainView from "./components/MainView/PulseMainView";

import PulseListView from "./components/MainView/list/PulseListView";
import PulseFormView2 from "./components/MainView/form/formView";
import PulseSystemScripts from "./components/MainView/PulseScripts/PulseSystemScripts";
import PulseDebugger from "./components/MainView/debugger/PulseDebugger";

// AppLayout Component
const AppLayout = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    // Function to toggle the sidebar's collapse state
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="flex flex-col h-screen">
            {/* Pass toggleSidebar function to TopBar */}
            <TopBar onToggleSidebar={toggleSidebar} isCollapsed={isCollapsed} />
            <div className="flex flex-1 overflow-hidden min-h-0">
                {/* SideBar with collapsible state */}
                <SideBar isCollapsed={isCollapsed} onToggleSidebar={toggleSidebar} />
                {/* MainView remains static */}
                <MainView  />
            </div>
        </div>
    );
};

function App() {
    const { isAuthenticated } = useAuth();

    console.log("isAuthenticated: " + isAuthenticated);

    const element = useRoutes([
        { path: "/login", element: <Login /> },
        {
            path: "/",
            element: isAuthenticated ? <AppLayout /> : <Navigate to="/login" />,
            children: [
                { index: true, element: <Navigate to="/home" replace /> },
                { path: "home", element: <PulseSystemScripts /> },
                { path: "utils/execute_script", element: <PulseSystemScripts /> },
                { path: "list/:collectionName", element: <PulseListView /> },
                { path: "form/:collectionName", element: <PulseFormView2 /> },
                { path: "debug", element: <PulseDebugger /> },
            ],
        },
        { path: "*", element: <h1>404 Not Found</h1> }
    ]);
    console.log("useRoutes element:", element);
    return element;
}

export default App;
