import React, {useState, useEffect, useRef, useCallback, useMemo} from "react";
import ContextMenu from "./PulseListContextMenu";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import PulseListFilter from "./PulseListFilter";
import {useAuth} from "../../../auth/PulseAuthContext";

const PulseListView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { collectionName } = useParams(); 
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const view = queryParams.get('view') || 'default';
  const [filters, setFilters] = useState([]);
  const {fetchData} = useAuth();

  const contextMenuRef = useRef(null);
  const [contextMenu, setContextMenu] = useState({ visible: false, position: { x: 0, y: 0 } });
  const [checkedState, setCheckedState] = useState([]);
  const [columnLayout, setColumnLayout] = useState([]);
  const [listData, setListData] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [count, setCount] = useState(0);
  // cosnt [filters, setFilters] = useState([]); // [ {column: '', filterType: '', value: ''}

  const handleViewClick = (systemID) => {
    navigate(`/form/${collectionName}?id=${systemID}&view=${view}`);
  };

  const handleNewClick = () => {
    navigate(`/form/${collectionName}?id=-1&view=${view}`);
  };

  // Function to fetch reference data for the entire list
  const fetchReferenceValues = useCallback((listData) => {
    try {
      const updatedListData = listData.map((item) => {
        const updatedItem = { ...item };

        for (const key in updatedItem) {
          if (updatedItem[key] && typeof updatedItem[key] === 'object' && updatedItem[key] !== null) {
            if (updatedItem[key].displayValue) {
              // Use the display value for reference type fields
              const referenceCollection = updatedItem[key].reference;
              const systemID = updatedItem[key].systemID;
              updatedItem[key] = (<span onClick={() => navigate(`/form/${referenceCollection}?id=${systemID}&view=default`)}
                                        className="text-blue-500 hover:text-blue-700 hover:underline cursor-pointer">{updatedItem[key].displayValue}</span>);
            } else {
              // If there's no displayValue, convert the object to a string
              updatedItem[key] = updatedItem[key].systemID;
            }
          } else {
            // Ensure all other values are strings for React rendering
            updatedItem[key] = String(updatedItem[key]);
          }
        }

        return updatedItem;
      });
      return updatedListData;
    } catch (error) {
      console.error("Failed to fetch reference values:", error);
      return listData;
    }
  }, [navigate]);

  const fetchColumnLayout = useCallback(async () => {
    try {
      //need to change the url to the correct one system_registry will be replaced by the collection name
      let urlBase = '/list/header/';
      if(collectionName){
        urlBase = urlBase + collectionName;
      } 
      if(view){
        urlBase = urlBase + '?view=' + view;
      }
      console.log('Fetching column layout:', urlBase);
      const response = await fetchData(urlBase);
      console.log('Column layout:', response.data);
      setColumnLayout(response.data.result.listFields);
    } catch (error) {
      console.error('Failed to fetch column layout:', error);
    }
  }, [collectionName, fetchData, view]);

  const fetchListData = useCallback(async () => {
    try {
      //need to change the url to the correct one system_registry will be replaced by the collection name
      let urlBase = `/list/${collectionName}?view=${view}`;

      const urlFilter = queryParams.get("filter");

      if (urlFilter) {
        const encodedFilter = encodeURI(urlFilter);
        urlBase += `&filter=${encodedFilter}`;
      }
      console.log("Fetching list data:", urlBase);
      const response = await fetchData(urlBase);
      console.log("List data:", response.data);
      setCount(response.data.count);
      const updatedListData = fetchReferenceValues(response.data.data);
      setListData(updatedListData);
      setCheckedState(new Array(response.data.data.length).fill(false));
    } catch (error) {
      console.error("Failed to fetch list data:", error);
    }
  }, [collectionName, fetchData, fetchReferenceValues, queryParams, view]);

  useEffect(() => {
    setCheckedState([]);
    setColumnLayout([]);
    setListData([]); 
    setCheckAll(false);

    fetchColumnLayout();
    fetchListData();
  }, [collectionName, view, filters, location.key, fetchColumnLayout, fetchListData]);

  useEffect(() => {

    const handleClickOutside = (event) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
        setContextMenu({ ...contextMenu, visible: false });
      }
    };

    // Add when the menu is visible, remove when not
    if (contextMenu.visible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contextMenu, contextMenu.visible]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      position: { x: event.pageX, y: event.pageY },
    });
  };
  
  const handleCloseContextMenu = () => {
    setContextMenu({ ...contextMenu, visible: false });
  };
  
  const handleRefresh = () => {
    // Implement your refresh logic here
    console.log("Refreshing data...");
    fetchListData();
    handleCloseContextMenu();
  };

  const handleCheckAll = (event) => {
    setCheckAll(event.target.checked);
    setCheckedState(new Array(listData.length).fill(event.target.checked));
  };

  const handleSingleCheck = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const handleApplyFilters = (filterCriteria) => {
    // const formattedFilters = filterCriteria.map(f => `${f.column}${f.filterType}${f.value}`).join("^");
    setFilters(filterCriteria);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filter", filterCriteria);
    const newSearch = `?${searchParams.toString()}`;
    navigate(`${location.pathname}${newSearch}`);
    fetchListData();
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between p-4 bg-gray-100 border-b border-gray-200">
        <div>
          <button
            onClick={handleNewClick}
            className=" bg-gray-500 hover:bg-gray-800 text-white font-bold py-1.5 px-4 rounded"
          >
            New
          </button>
        </div>
        <div className="text-right">
          <span className="text-lg font-semibold text-gray-700">
            {count} records
          </span>
        </div>
      </div>
      <PulseListFilter columns={columnLayout.map(col => col.field)} onApplyFilters={handleApplyFilters} />
      <div className="flex-grow overflow-x-auto">
        <table className="min-w-full divide-y table-fixed divide-gray-200">
          <thead
            className="whitespace-nowrap bg-gray-50 sticky top-0 z-10"
            onContextMenu={handleContextMenu}
          >
            <tr>
              <th className="px-6 py-4 text-left">
                <div className="inline-flex items-center">
                  <label
                    className="relative flex items-center rounded-full cursor-pointer"
                    htmlFor="checkbox"
                  >
                    <input
                      type="checkbox"
                      className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-gray-700 before:opacity-0 before:transition-opacity checked:border-gray-700 checked:bg-gray-700 checked:before:bg-gray-700 hover:before:opacity-10"
                      id="checkbox"
                      onClick={handleCheckAll}
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                </div>
              </th>
              <th className="w-12 p-1"> {/* Empty <th> for alignment */} </th>
              {columnLayout?.map((field, index) => (
                <th
                  key={index}
                  className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider whitespace-nowrap text-sm"
                >
                  {field.label || field.field}
                </th>
              ))}
            </tr>
            <tr>
              <th className="w-12 p-1"> {/* Empty <th> for alignment */} </th>
              <th className="w-12 p-1"> {/* Empty <th> for alignment */} </th>
              {columnLayout?.map((field, index) => (
                <th key={index} className="w-12 p-1">
                  <input
                    type="text"
                    className="mt-1 block w-full border border-gray-400 hover:bg-gray-200 rounded-md py-1 px-3 focus:outline-none focus:bg-white focus:ring-2 focus:ring-inset focus:ring-gray-700 font-normal"
                    placeholder="Search"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {listData.map((row, rowIndex) => (
              <tr key={rowIndex} className="even:bg-gray-100 hover:bg-gray-200">
                <td className="px-6 pt-2 text-left">
                <div className="inline-flex items-center">
                  <label
                    className="relative flex items-center rounded-full cursor-pointer"
                    htmlFor={rowIndex}
                  >
                    <input
                      type="checkbox"
                      className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-gray-700 before:opacity-0 before:transition-opacity checked:border-gray-700 checked:bg-gray-700 checked:before:bg-gray-700 hover:before:opacity-10"
                      id={rowIndex}
                      checked={checkedState[rowIndex]}
                      onChange={() => handleSingleCheck(rowIndex)}
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                  </div>
                </td>
                <td className="px-1 py-4 text-left">
                  <button
                    onClick={() => handleViewClick(row.systemID)}
                    className="bg-gray-500 hover:bg-gray-800 text-white rounded px-2 py-1 text-sm"
                  >
                    View
                  </button>
                </td>
                {columnLayout.map((col) => (
                  <td
                    key={col.field}
                    className="w-32 break-words px-6 py-4 text-sm text-gray-700 hover:bg-gray-300"
                  >
                    {typeof row[col.field] === "boolean"
                      ? row[col.field].toString()
                      : row[col.field]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ContextMenu
        ref={contextMenuRef}
        visible={contextMenu.visible}
        position={contextMenu.position}
        onClose={handleCloseContextMenu}
        onRefresh={handleRefresh}
      />
      <div className="pagination-bar bg-gray-100 px-4 py-3 flex items-center justify-between border-t border-gray-200 md:p-5"></div>
    </div>
  );
};

export default PulseListView;
