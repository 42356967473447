import React, {useState, useEffect, useCallback} from "react";
import MonacoEditor from 'react-monaco-editor';
import { useAuth } from "../../../auth/PulseAuthContext";

const PulseSystemScripts = () => {
    const [code, setCode] = useState('');
    const {postData} = useAuth();

    const editorDidMount = (editor, monaco) => {
        editor.focus();
    };

    const handleExecute = useCallback( (event) => {
        event && event.preventDefault();
        try {
            console.log('Executing script:', code);
            if (!code) {
                console.error('Script is empty');
                return;
            }
            console.log('Executing script:', code);
            postData('/script/execute', {script: code})
            // axios.post('http://localhost:3004/pulse/api/v1/script/execute', {script: code })
                .then(response => {
                    console.log('Response:', response.data);
                }).catch(error => {
                    console.error('API Failed to execute script:', error);
                });
        } catch (error) {
            console.error('Failed to execute script:', error);
        }
    }, [code, postData]);

    const options = {
        selectOnLineNumbers: true,
        roundedSelection: true,
        readOnly: false,
        cursorStyle: 'line',
        automaticLayout: true,
        autoIndent: true,
        codeLens: true,
        dragAndDrop: true,
        wordWrap: "on"

    };

    const onChange2 = (newScript) => {
        setCode(newScript);
    };

    //**USE EFFECTS */
    useEffect(() => {
        const handleKeyPress = (event) => {
            if(event.key.toLowerCase() === 'e' && (event.ctrlKey || event.metaKey)) {   
                event.preventDefault();
                handleExecute(event);
            }
        }
        document.addEventListener('keydown', handleKeyPress);

        return () => {      
            document.removeEventListener('keydown', handleKeyPress);
        }
    }, [code, handleExecute]);

    return (
        <div className="flex flex-col h-full bg-white shadow overflow-auto">
            <div className="flex-grow p-4">
                <label className="text-sm font-semibold text-gray-700 block mb-2">Execute Script
                <div className="editor-container p-2 border border-gray-500 rounded-lg">
                <MonacoEditor
                    id="editor"
                    width="100%"
                    height="500"
                    language="javascript"
                    value={code}
                    onChange={onChange2}
                    editorDidMount={editorDidMount}
                    options={options}
                /></div></label>
                
            </div>
            <div className="p-4 border-t bg-gray-100" >
                <button type="button" className="px-4 py-2 bg-gray-500 hover:bg-gray-800 text-white font-bold rounded focus:outline-none focus:shadow-outline"
                onClick={handleExecute}>
                    Execute
                </button>
                </div>
        </div>
    );
}

export default PulseSystemScripts;