import React, { useState} from 'react';
import { useLocation } from "react-router-dom";
import PulseFormContextMenu from './PulseFormContextMenu';
import PulseUIActionButton from './PulseFormUIActionButton';
import {PulseFormProvider, usePulseForm} from '../../../utils/PFormManager';

const PulseFormViewV2Content = () => {
  const { formStructure, formActions, renderSection } = usePulseForm();
  const [id] = useState(new URLSearchParams(useLocation().search).get("id"));

  return (
    <div className="relative mt-4 mx-8 rounded-lg shadow-lg bg-white">
      <div
        className="context-menu-trigger flex flex-row flex-wrap items-start justify-start gap-2 p-4 bg-gray-200 rounded-t-lg"
        top-bar-menu-context='true'
      >
        {formActions &&
          formActions.map((action) => (
            <PulseUIActionButton
              action={action}
              // buttonText={action.name}
              // script={action.script}
            />
          ))}
      </div>
      <div className="pt-0 pb-4">
        {formStructure &&
          formStructure &&
          formStructure.map((section) => (
            <div key={section.systemID}>{renderSection(section)}</div>
          ))}
      </div>
        <PulseFormContextMenu id={id} />
    </div>
  );
};

const PulseFormViewV2 = () => {
    return (
        <PulseFormProvider>
            <PulseFormViewV2Content />
        </PulseFormProvider>
    );
}

export default PulseFormViewV2;
